import React, { useState, useEffect } from "react";
import {
  Target,
  Check,
  Database,
  Bot,
  TrendingUp,
  DollarSign,
  Shield,
  BarChart,
  Award,
  X,
} from "lucide-react";
import mixpanel from "mixpanel-browser";
import { config } from "./config";
function LandingPage() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [activeTab, setActiveTab] = useState("research");
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  useEffect(() => {
    mixpanel.init(config.mixpanel.token, {
      debug: process.env.NODE_ENV !== "production",
      ignore_dnt: process.env.NODE_ENV !== "production",
      track_pageview: true,
      persistence: "localStorage",
    });
    const handleScroll = () => setIsScrolled(window.scrollY > 10);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-br from-slate-50 via-slate-100 to-gray-100 text-gray-900">
      <header
        className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${
          isScrolled ? "bg-white/80 backdrop-blur-md shadow-md" : ""
        }`}
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 flex justify-between items-center">
          <div className="flex items-center gap-2">
            <Shield className="h-8 w-8 text-blue-800" />
            <div className="text-2xl font-bold text-blue-800">
              {config.labels.brandName}
            </div>
          </div>
          <div className="flex">
            <a
              href={config.links.blog}
              target="_blank"
              rel="noreferrer"
              className="text-blue-700 px-4 py-2 text-sm font-medium hover:text-blue-800"
            >
              Blog
            </a>
          </div>
        </div>
      </header>
      <main className="pt-24">
        <section className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
          <div className="text-center mb-16">
            <div className="flex justify-center gap-2 mb-6">
              <Award className="h-6 w-6 text-blue-600" />
              <span className="text-sm font-medium text-blue-600">
                Trusted by Top Real Estate Teams Nationwide
              </span>
            </div>
            <h1 className="text-5xl md:text-6xl font-bold mb-6 text-blue-800">
              Cut Real Estate Lead Costs by 90%
            </h1>
            <p className="text-2xl text-gray-700 mb-6 max-w-3xl mx-auto">
              AI agents that help real estate teams find and engage qualified
              buyers and sellers at a fraction of traditional marketing costs
            </p>
            <div className="flex flex-col md:flex-row justify-center items-center gap-8 mb-12">
              <div className="flex items-center gap-2">
                <DollarSign className="h-6 w-6 text-blue-600" />
                <span className="text-lg text-gray-600">
                  Average CAC: ~$1,185 → ~$118
                </span>
              </div>
              <div className="flex items-center gap-2">
                <Database className="h-6 w-6 text-blue-600" />
                <span className="text-lg text-gray-600">
                  6x More Qualified Leads
                </span>
              </div>
              <div className="flex items-center gap-2">
                <TrendingUp className="h-6 w-6 text-blue-600" />
                <span className="text-lg text-gray-600">
                  Instant Implementation
                </span>
              </div>
            </div>
            {/* CTA Button remains the same */}
            <div className="flex flex-col md:flex-row gap-4 justify-center">
              <a
                href={config.typeform.waitlistForm}
                target="_blank"
                rel="noreferrer"
                onClick={(_: any) => {
                  if (process.env.NODE_ENV === "production") {
                    mixpanel.track("Demo Request Clicked");
                  }
                }}
                className="inline-block bg-blue-700 text-white px-8 py-4 rounded-full text-lg font-medium hover:bg-blue-800 transition-all duration-300 shadow-lg hover:shadow-xl"
              >
                Join Waitlist
              </a>
            </div>
          </div>
          {/* Demo */}
          <section className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
            <h2 className="text-4xl font-bold text-center mb-12 bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-blue-800">
              See {config.labels.brandName} in Action
            </h2>
            <p className="text-center text-gray-600 italic mb-8">
              *Our MVP demo is continuously evolving - check back regularly for
              updated feature demonstrations
            </p>
            <div className="relative aspect-video rounded-3xl overflow-hidden shadow-2xl">
              <video
                controls
                id="demo-video"
                className="w-full h-full fit-cover"
                src={config.videos.demoSource}
                poster={process.env.PUBLIC_URL + config.videos.demoPoster}
                onClick={(_) => {
                  if (process.env.NODE_ENV === "production") {
                    mixpanel.track("Video Player Clicked");
                    (window as any).heap.track("Video Player Clicked");
                  }
                }}
              >
                Your browser does not support the video tag.
              </video>
            </div>
          </section>
          {/* Case Studies */}
          <div id="case-studies" className="mb-20">
            <h2 className="text-3xl font-bold text-center mb-12">
              Results from Leading Real Estate Teams
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {[
                {
                  name: "Luxury Real Estate Team",
                  metric: "82% Lower CAC",
                  quote:
                    "Scaled from 15 to 90 qualified leads monthly while reducing cost per acquisition",
                },
                {
                  name: "Multi-Market Brokerage",
                  metric: "6x Lead Volume",
                  quote:
                    "AI agents helped successfully expand into 2 new markets within 2 weeks",
                },
                {
                  name: "Mid-Size Agency",
                  metric: "5x ROI",
                  quote:
                    "Better qualified buyers and sellers at a fraction of our previous ad spend",
                },
              ].map((study, index) => (
                <div key={index} className="bg-white p-6 rounded-xl shadow-lg">
                  <h3 className="text-xl font-semibold mb-2">{study.metric}</h3>
                  <p className="text-gray-600">{study.quote}</p>
                </div>
              ))}
            </div>
          </div>
          {/* How It Works Section */}
          <div
            id="how-it-works"
            className="bg-white rounded-3xl p-8 shadow-xl relative overflow-hidden"
          >
            <h2 className="text-3xl font-bold text-center mb-12">
              How It Works
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {[
                {
                  icon: Target,
                  title: "AI Market Analysis",
                  text: "AI analyzes your top performing listings and clients to build ideal buyer/seller profiles",
                },
                {
                  icon: Bot,
                  title: "Smart Lead Finding",
                  text: "Agents find and verify potential clients across social media, professional networks, and local communities",
                },
                {
                  icon: BarChart,
                  title: "Automated Engagement",
                  text: "Personalized outreach using proven real estate templates and conversion best practices",
                },
              ].map((step, index) => (
                <div key={index} className="text-center">
                  <div className="mb-4">
                    <step.icon className="h-12 w-12 text-blue-600 mx-auto" />
                  </div>
                  <h3 className="text-xl font-semibold mb-2">{step.title}</h3>
                  <p className="text-gray-600 mb-4">{step.text}</p>
                </div>
              ))}
            </div>
          </div>

          {/* Industry Focus */}
          <div className="bg-white rounded-3xl p-8 shadow-xl relative overflow-hidden mb-20">
            <div className="absolute top-0 left-0 w-full h-2 bg-blue-600"></div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
              <div>
                <h2 className="text-3xl font-semibold mb-6 text-blue-800">
                  Built for Real Estate Teams:
                </h2>
                <ul className="space-y-6">
                  {[
                    "Luxury Real Estate Teams (~$1,185+ avg. CAC)",
                    "Multi-Market Brokerages",
                    "Growing Agencies",
                    "New Development Sales",
                    "Commercial Real Estate Teams",
                  ].map((item, index) => (
                    <li key={index} className="flex items-start">
                      <div className="flex-shrink-0 w-8 h-8 bg-blue-600 rounded-full flex items-center justify-center mr-4">
                        <Check className="h-5 w-5 text-white" />
                      </div>
                      <span className="text-lg text-gray-700">{item}</span>
                    </li>
                  ))}
                </ul>
              </div>
              {/* Interactive Product Demo section remains the same */}
              <div className="relative">
                <div className="bg-gray-50 rounded-xl p-4 shadow-lg">
                  <div className="flex gap-2 mb-4">
                    <button
                      onClick={() => setActiveTab("research")}
                      className={`px-4 py-2 rounded-lg text-sm font-medium ${
                        activeTab === "research"
                          ? "bg-blue-600 text-white"
                          : "text-gray-600"
                      }`}
                    >
                      Research
                    </button>
                    <button
                      onClick={() => setActiveTab("sourcing")}
                      className={`px-4 py-2 rounded-lg text-sm font-medium ${
                        activeTab === "sourcing"
                          ? "bg-blue-600 text-white"
                          : "text-gray-600"
                      }`}
                    >
                      Sourcing
                    </button>
                    <button
                      onClick={() => setActiveTab("outreach")}
                      className={`px-4 py-2 rounded-lg text-sm font-medium ${
                        activeTab === "outreach"
                          ? "bg-blue-600 text-white"
                          : "text-gray-600"
                      }`}
                    >
                      Outreach
                    </button>
                  </div>
                  <div>
                    <div
                      className="aspect-video bg-white rounded-lg shadow-inner overflow-hidden cursor-pointer"
                      onClick={() =>
                        setSelectedImage(`${activeTab}-example.webp`)
                      }
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL + `${activeTab}-example.webp`
                        }
                        alt={`${activeTab} screenshot`}
                        className="w-full h-full object-contain"
                      />
                    </div>

                    {selectedImage && (
                      <div
                        className="fixed inset-0 bg-black bg-opacity-75 z-50 flex items-center justify-center p-4"
                        onClick={() => setSelectedImage(null)}
                      >
                        <button
                          className="absolute top-4 right-4 text-white"
                          onClick={() => setSelectedImage(null)}
                        >
                          <X className="h-6 w-6" />
                        </button>
                        <img
                          src={process.env.PUBLIC_URL + selectedImage}
                          alt="Full size view"
                          className="max-w-full max-h-[90vh] object-contain"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* Final CTA */}
            <div className="bg-gradient-to-br from-blue-800 to-blue-900 text-white rounded-3xl p-12 my-20">
              <div className="max-w-4xl mx-auto text-center">
                <h2 className="text-4xl font-bold mb-6">
                  Ready to Cut Your Lead Acquisition Cost by 90%?
                </h2>

                <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
                  <div className="bg-white/10 rounded-xl p-6">
                    <div className="text-3xl font-bold mb-2">~$1,185</div>
                    <div className="text-sm text-blue-200">
                      Current Average CAC
                    </div>
                  </div>

                  <div className="bg-white/10 rounded-xl p-6">
                    <div className="text-3xl font-bold mb-2">90%</div>
                    <div className="text-sm text-blue-200">Cost Reduction</div>
                  </div>

                  <div className="bg-white/10 rounded-xl p-6">
                    <div className="text-3xl font-bold mb-2">~1 Week</div>
                    <div className="text-sm text-blue-200">
                      To First Results
                    </div>
                  </div>
                </div>

                <div className="space-y-4">
                  <a
                    href={config.typeform.waitlistForm}
                    className="inline-block bg-white text-blue-800 px-8 py-4 rounded-full text-lg font-medium hover:bg-blue-50 transition-all duration-300 shadow-lg hover:shadow-xl"
                  >
                    Join Waitlist
                  </a>
                  <div className="text-sm text-blue-200">
                    Limited to 5 real estate teams this month | 30-day
                    money-back guarantee
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <footer className="bg-white border-t border-gray-200">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="flex items-center gap-2 mb-4 md:mb-0">
              <Shield className="h-6 w-6 text-blue-800" />
              <span className="text-lg font-bold text-blue-800">
                {config.labels.brandName}
              </span>
            </div>
            <p className="text-sm text-gray-600">
              © {new Date().getFullYear()} {config.labels.brandName}. All rights
              reserved.
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}
export default LandingPage;
